import request from '@/utils/request'
// 获取小程序配置图片列表
export function getImgList(data) {
  return request('post', '/apm/shop/ad/list', data)
}

// 上传轮播图
export function getImgEdit(data) {
  return request('post', '/apm/shop/ad/mod', data)
}

// 轮播图排序
export function getImgChange(data) {
  return request('post', '/apm/shop/ad/change', data)
}

// 删除轮比兔
export function getImgDel(data) {
  return request('post', '/apm/shop/ad/del', data)
}

// 门店wifi列表
export function getWifiList(data) {
  return request('post', '/apm/shop/wifi/list', data)
}

// 门店wifi编辑
export function getWifiEdit(data) {
  return request('post', '/apm/shop/wifi/mod', data)
}

// 常见问题查询
export function getEditorInfo(data) {
  return request('post', '/apm/shop/tips/findById', data)
}

// 常见问题保存
export function getEditorEdit(data) {
  return request('post', '/apm/shop/tips/mod', data)
}

// 订单详情查询
export function getOrderInfo(data) {
  return request('post', '/apm/shop/copy/findById', data)
}

// 订单详情保存
export function getOrderEdit(data) {
  return request('post', '/apm/shop/copy/mod', data)
}

// 分享配置查询
export function getShareInfo(data) {
  return request('post', '/apm/shop/share/find', data)
}

// 分享配置保存
export function getShareEdit(data) {
  return request('post', '/apm/shop/share/mode', data)
}