<template>
  <div class="order">
    <div class="left">
      <p class="left-title">订单详情页说明文案：<span></span></p>
      <quill-editor v-model="content" ref="myQuillEditor" :options="editorOption" @change="onEditorChange($event)">
      </quill-editor>
      <div class="editor-footer">
        <el-button type="primary" @click="onEditor()">保存</el-button>
      </div>
    </div>
    <div class="right">
      <p>小程序展示样式</p>
      <img src="~@/assets/images/mini/order.png" alt="">
    </div>
    <!-- 图片上传组件 -->
    <UpLoad @fileIds="getFileIds" ref="up" :fileType="1"></UpLoad>
  </div>
</template>

<script>
import UpLoad from "@/components/upload";
import {
  getFileList
} from "@/api/common";
import {
  getOrderInfo,
  getOrderEdit,
} from "@/api/shop/mini";
import { rules } from "@/db/rules";

import { quillEditor } from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import * as Quill from 'quill'
// 调整上传图片大小
import ImageResize from 'quill-image-resize-module'
Quill.register('modules/imageResize', ImageResize)


export default {
  components: { UpLoad, quillEditor },
  data() {
    return {
      rules,
      uploadTitle: "",
      params: {
        shopId: "",//店铺ID
        adId: "", //轮播ID
        adPic: "", //图片id集合
      },
      content: "",
      editorOption: {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
              ['blockquote', 'code-block'], // 引用  代码块
              [{ header: 1 }, { header: 2 }], // 1、2 级标题
              [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
              [{ script: 'sub' }, { script: 'super' }], // 上标/下标
              [{ indent: '-1' }, { indent: '+1' }], // 缩进
              [{ direction: 'rtl' }], // 文本方向
              [{ size: ['12', '14', '16', '18', '20', '22', '24', '28', '32', '36'] }], // 字体大小
              [{ header: [1, 2, 3, 4, 5, 6] }], // 标题
              [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
              // [{ font: ['songti'] }], // 字体种类
              [{ align: [] }], // 对齐方式
              ['clean'], // 清除文本格式
              ['image', 'video'] // 链接、图片、视频
            ],
            handlers: {
              'image': (value) => {
                if (value) {
                  this.onUpload('富文本');
                } else {
                  this.quill.format('image', false)
                }
              }
            }
          },
          // 调整图片大小
          imageResize: {
            displayStyles: {
              backgroundColor: 'black',
              border: 'none',
              color: 'white'
            },
            modules: ['Resize', 'DisplaySize', 'Toolbar']
          }
        },
        placeholder: '请填写正文'
      }
    };
  },
  created() {
    let merchantShopId = window.localStorage.getItem('playOne-store-merchantShopId');
    if (merchantShopId) {
      this.params.shopId = JSON.parse(merchantShopId)[1];
    }
  },
  mounted() {
    this.getOrderInfo();
  },
  methods: {
    // 【请求】富文本信息
    getOrderInfo() {
      let data = {
        shopId: this.params.shopId
      };
      getOrderInfo(data).then(res => {
        if (res.isSuccess == "yes") {
          if (res.data) {
            this.content = res.data.copyContent;
          }
        }
      })
    },

    // 【请求】富文本保存
    getOrderEdit() {
      let data = {
        shopId: this.params.shopId,
        copyContent: this.content,
      }
      getOrderEdit(data).then(res => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.getOrderInfo();
        }
      })
    },

    cutUrl(url) {
      let index = url.indexOf('?');
      return url.slice(0, index)
    },


    // 【请求】获取文件信息
    getFileList(fileIds) {
      let data = {
        fileIds
      }
      getFileList(data).then(res => {
        if (res.isSuccess == "yes") {
          let url = this.cutUrl(res.data[0].url);//获取图片路径
          let quill = this.$refs.myQuillEditor.quill;//获取光标信息
          let length = quill.getSelection().index;//获取当前光标位置
          quill.insertEmbed(length, 'image', url);//在光标处插入图片
          quill.setSelection(length + 1);//将光标移至图片后
        } else {
          this.$message.error('图片插入失败！')
        }
      })
    },

    // 【监听】获取上传文件ID集合
    getFileIds(fileIds) {
      this.getFileList(fileIds);
    },

    // 【监听】文件上传
    onUpload(title) {
      this.uploadTitle = title;
      this.$refs.up.onUpload();
    },

    // 内容改变事件
    onEditorChange({ quill, html, text }) {
      this.content = html
    },

    // 【监听】保存富文本
    onEditor() {
      this.getOrderEdit()
    },
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/theme';

.order {
  display: flex;
}

.foot-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: .1rem;
}
.quill-editor{
  width: 90%;
}

.editor-footer {
  margin-top: .15rem;
  display: flex;
}

.left {
  width: 60%;
}

.left-title {
  margin-bottom: .15rem;

  span {
    font-size: .14rem;
    color: #999999;
  }
}

.right {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin: .1rem 0;
  }
}
</style>

<style>
.ql-container {
  min-height: 4rem;
}
</style>

